<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.1362 10.5456C15.5768 9.88624 16.1435 9.31833 16.8014 8.8776C17.7263 8.25802 18.8316 7.88979 20.0207 7.87231C18.8318 7.85589 17.7266 7.48779 16.8016 6.86796C16.1439 6.42723 15.5773 5.85924 15.1367 5.19991C14.5164 4.27185 14.1457 3.16283 14.1214 1.97308C14.112 3.16508 13.7474 4.2738 13.1289 5.20091C12.6882 5.86141 12.1186 6.42973 11.4567 6.87032C10.527 7.48919 9.415 7.85604 8.22221 7.87231C9.41451 7.88962 10.5265 8.25718 11.4564 8.87649C12.118 9.31707 12.6874 9.88507 13.1281 10.545C13.747 11.4719 14.112 12.5802 14.1214 13.7715C14.1457 12.5817 14.5162 11.4732 15.1362 10.5456ZM14.1325 8.9642C14.459 8.56545 14.8241 8.19961 15.2223 7.87266C14.8245 7.54588 14.4596 7.18026 14.1335 6.78179C13.8062 7.18098 13.4396 7.54684 13.0399 7.87359C13.4392 8.20006 13.8055 8.56552 14.1325 8.9642Z"
      :fill="fill"
    />
    <path
      d="M22.0001 11.9999C22.0001 13.1045 21.1047 13.9999 20.0001 13.9999C19.9633 13.9999 19.9267 13.9989 19.8904 13.997L16.9356 17.5058C16.7115 18.3655 15.9299 18.9999 15.0001 18.9999C14.0671 18.9999 13.2833 18.3611 13.0624 17.497L10.5314 16.2865C10.1645 16.7227 9.61469 16.9999 9.00011 16.9999C8.64184 16.9999 8.30557 16.9057 8.01473 16.7407L6.81708 18.163C6.93457 18.4176 7.00011 18.7011 7.00011 18.9999C7.00011 20.1045 6.10468 20.9999 5.00011 20.9999C3.89554 20.9999 3.00011 20.1045 3.00011 18.9999C3.00011 17.8954 3.89554 16.9999 5.00011 16.9999C5.05917 16.9999 5.11764 17.0025 5.1754 17.0075L7.00702 14.8325C7.09209 13.8063 7.95194 12.9999 9.00011 12.9999C9.82713 12.9999 10.5369 13.5019 10.8414 14.2178L13.624 15.5486C13.9825 15.2086 14.467 14.9999 15.0001 14.9999C15.4213 14.9999 15.812 15.1301 16.1343 15.3524L18.2083 12.8895C18.0751 12.6216 18.0001 12.3195 18.0001 11.9999C18.0001 10.8954 18.8955 9.99994 20.0001 9.99994C21.1047 9.99994 22.0001 10.8954 22.0001 11.9999Z"
      :fill="fill"
    />
    <path
      d="M8.11935 10.4998C7.4593 10.5192 6.85048 10.7408 6.35255 11.1048C6.08932 11.2972 5.85708 11.5294 5.66465 11.7927C5.30066 12.2906 5.07911 12.8994 5.05968 13.5595C5.04025 12.8994 4.8187 12.2906 4.4547 11.7927C4.26227 11.5294 4.03004 11.2972 3.7668 11.1048C3.26888 10.7408 2.66005 10.5192 2 10.4998C2.66005 10.4804 3.26888 10.2588 3.7668 9.89483C4.03004 9.7024 4.26227 9.47016 4.4547 9.20693C4.8187 8.709 5.04025 8.10017 5.05968 7.44012C5.07911 8.10017 5.30066 8.709 5.66465 9.20693C5.85708 9.47016 6.08932 9.7024 6.35255 9.89483C6.85048 10.2588 7.4593 10.4804 8.11935 10.4998Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  name: 'AlertsIcon',
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
