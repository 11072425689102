<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.37139 3.07155C8.88417 3.27666 9.13359 3.85863 8.92848 4.37141L7.47703 8.00002L16.523 8.00002L15.0715 4.37141C14.8664 3.85863 15.1158 3.27666 15.6286 3.07155C16.1414 2.86643 16.7234 3.11585 16.9285 3.62863L18.677 8.00002H22V10H20.8346L19.2825 18.5367L19.2739 18.5837L19.2739 18.5837C19.21 18.9354 19.1469 19.2832 19.0576 19.5686C18.9561 19.8932 18.789 20.25 18.4469 20.5355C18.1048 20.821 17.7239 20.9216 17.3864 20.9634C17.0896 21.0002 16.736 21.0001 16.3786 21L16.3308 21H7.66915L7.62137 21C7.26396 21.0001 6.91038 21.0002 6.61362 20.9634C6.27609 20.9216 5.89517 20.821 5.55308 20.5355C5.211 20.25 5.04392 19.8932 4.94239 19.5686C4.85313 19.2832 4.78995 18.9353 4.72608 18.5837L4.71754 18.5367L3.16542 10H2V8.00002H5.32297L7.07152 3.62863C7.27664 3.11585 7.85861 2.86643 8.37139 3.07155ZM5.19821 10L6.68528 18.1789C6.76121 18.5965 6.8035 18.8191 6.8512 18.9716L6.85315 18.9778L6.85956 18.9786C7.01816 18.9982 7.2447 19 7.66915 19H16.3308C16.7553 19 16.9818 18.9982 17.1404 18.9786L17.1469 18.9778L17.1488 18.9716C17.1965 18.8191 17.2388 18.5965 17.3147 18.1789L18.8018 10H18.0232C18.0079 10.0004 17.9926 10.0004 17.9774 10L6.02267 10C6.00739 10.0004 5.99206 10.0004 5.97671 10H5.19821ZM9 12L15 12V14L9 14V12Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: '',
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
