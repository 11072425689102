<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.75605 14.4106C10.0815 14.736 10.0815 15.2637 9.75605 15.5891C9.43061 15.9145 8.90298 15.9145 8.57754 15.5891L3.58343 10.595C3.25477 10.2663 3.25471 9.73341 3.58343 9.40469L8.57754 4.41058C8.90298 4.08514 9.43061 4.08515 9.75605 4.41058C10.0815 4.73602 10.0815 5.26366 9.75605 5.58909L5.34531 9.99983L9.75605 14.4106Z"
      fill="#9295A5"
    />
    <path
      d="M16.4227 14.4106C16.7482 14.736 16.7482 15.2637 16.4227 15.5891C16.0973 15.9145 15.5696 15.9145 15.2442 15.5891L10.2501 10.595C9.92144 10.2663 9.92137 9.73341 10.2501 9.40469L15.2442 4.41058C15.5696 4.08514 16.0973 4.08515 16.4227 4.41058C16.7482 4.73602 16.7482 5.26366 16.4227 5.58909L12.012 9.99983L16.4227 14.4106Z"
      fill="#9295A5"
    />
  </svg>
</template>

<script>
export default {
  name: '',
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 32 },
  },
};
</script>
