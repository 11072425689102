<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00033 1.1665C8.46056 1.1665 8.83366 1.5396 8.83366 1.99984V3.74177C8.83366 4.20201 8.46056 4.57511 8.00033 4.57511C7.54009 4.57511 7.16699 4.20201 7.16699 3.74177V1.99984C7.16699 1.5396 7.54009 1.1665 8.00033 1.1665ZM3.16845 3.16794C3.49389 2.8425 4.02152 2.8425 4.34696 3.16794L5.522 4.34297C5.84743 4.66841 5.84743 5.19605 5.522 5.52149C5.19656 5.84692 4.66892 5.84692 4.34349 5.52149L3.16845 4.34645C2.84301 4.02101 2.84301 3.49337 3.16845 3.16794ZM12.8322 3.16795C13.1577 3.49338 13.1577 4.02102 12.8322 4.34646L11.6572 5.52151C11.3318 5.84695 10.8041 5.84695 10.4787 5.52151C10.1532 5.19607 10.1532 4.66843 10.4787 4.343L11.6537 3.16795C11.9792 2.84251 12.5068 2.84251 12.8322 3.16795ZM1.16699 7.99984C1.16699 7.5396 1.54009 7.1665 2.00033 7.1665L3.74226 7.1665C4.2025 7.1665 4.57559 7.5396 4.57559 7.99984C4.57559 8.46007 4.2025 8.83317 3.74226 8.83317L2.00033 8.83317C1.54009 8.83317 1.16699 8.46007 1.16699 7.99984ZM11.4251 7.99984C11.4251 7.5396 11.7982 7.1665 12.2584 7.1665H14.0003C14.4606 7.1665 14.8337 7.5396 14.8337 7.99984C14.8337 8.46007 14.4606 8.83317 14.0003 8.83317H12.2584C11.7982 8.83317 11.4251 8.46007 11.4251 7.99984ZM10.4787 10.4782C10.8041 10.1527 11.3318 10.1527 11.6572 10.4782L12.8322 11.6532C13.1577 11.9787 13.1577 12.5063 12.8322 12.8317C12.5068 13.1572 11.9792 13.1572 11.6537 12.8317L10.4787 11.6567C10.1532 11.3312 10.1532 10.8036 10.4787 10.4782ZM5.522 10.4782C5.84743 10.8036 5.84743 11.3313 5.522 11.6567L4.34696 12.8317C4.02152 13.1572 3.49389 13.1572 3.16845 12.8317C2.84301 12.5063 2.84301 11.9787 3.16845 11.6532L4.34349 10.4782C4.66892 10.1528 5.19656 10.1528 5.522 10.4782ZM8.00032 11.4246C8.46056 11.4246 8.83366 11.7977 8.83366 12.2579V13.9998C8.83366 14.4601 8.46056 14.8332 8.00033 14.8332C7.54009 14.8332 7.16699 14.4601 7.16699 13.9998L7.16699 12.2579C7.16699 11.7977 7.54009 11.4246 8.00032 11.4246Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: '',
  props: {
    fill: { type: String, required: false, default: '#1F284D' },
    size: { type: Number, required: false, default: 16 },
  },
};
</script>
