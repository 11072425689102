<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect :width="size" :height="size" rx="12" fill="#F3F3F4" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.33301 6.6665V16.6665V17.3332H5.99967H18.6663V15.9998H6.66634V6.6665H5.33301ZM10.8612 9.8613L7.99967 12.7228V14.6084L11.3326 11.2755L13.1949 13.1378L13.6663 13.6093L14.1377 13.1378L18.6663 8.60925V6.72363L13.6663 11.7236L11.804 9.8613L11.3326 9.38989L10.8612 9.8613Z"
      fill="#585C63"
    />
  </svg>
</template>

<script>
export default {
  name: '',
  props: {
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
