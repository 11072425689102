<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5097 2.12842C11.8142 1.95719 12.1858 1.95719 12.4903 2.12842L20.4903 6.62842C20.8051 6.80554 21 7.13873 21 7.5V16.5C21 16.8613 20.8051 17.1945 20.4903 17.3716L12.4903 21.8716C12.1858 22.0428 11.8142 22.0428 11.5097 21.8716L3.50974 17.3716C3.19486 17.1945 3 16.8613 3 16.5V7.5C3 7.13873 3.19486 6.80554 3.50974 6.62842L11.5097 2.12842ZM5 9.20985L11 12.5848V19.2902L5 15.9152V9.20985ZM13 19.2902L19 15.9152V9.20985L13 12.5848V19.2902ZM12 10.8527L17.9603 7.5L12 4.14735L6.03973 7.5L12 10.8527Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: '',
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
