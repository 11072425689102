<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.29289 5.70712L18.2929 19.7071L19.7071 18.2929L5.70711 4.29291L4.29289 5.70712ZM18.9113 16.083C20.4196 14.6759 21.3754 13.1394 21.7789 12.4181C21.926 12.1552 21.926 11.8448 21.7789 11.5819C20.9352 10.0735 17.6755 5.00001 11.9999 5.00001C10.7125 5.00001 9.54941 5.26105 8.51062 5.68231L9.68245 6.85414C10.398 6.62965 11.1701 6.50001 11.9999 6.50001C14.3586 6.50001 16.2517 7.54744 17.7017 8.84363C18.9647 9.97268 19.8316 11.2404 20.2875 12C19.8497 12.7295 19.0328 13.9277 17.8499 15.0216L18.9113 16.083ZM6.14988 8.97842L5.0885 7.91704C3.58022 9.32415 2.62435 10.8606 2.22089 11.5819C2.07383 11.8448 2.07383 12.1552 2.22089 12.4181C3.06462 13.9265 6.32429 19 11.9999 19C13.2873 19 14.4504 18.739 15.4892 18.3177L14.3173 17.1459C13.6018 17.3704 12.8297 17.5 11.9999 17.5C9.64121 17.5 7.74814 16.4526 6.29812 15.1564C5.03507 14.0274 4.16814 12.7596 3.7123 12C4.15008 11.2705 4.96703 10.0723 6.14988 8.97842ZM15.8645 13.0361C15.9529 12.7056 16 12.3583 16 12C16 9.79088 14.2091 8.00001 12 8.00001C11.7369 8.00001 11.4797 8.02541 11.2308 8.0739C11.1431 8.09099 11.0719 8.13135 11.0161 8.18773L15.8645 13.0361ZM8.18772 11.0162C8.13133 11.072 8.09098 11.1431 8.07388 11.2308C8.0254 11.4798 8 11.7369 8 12C8 14.2092 9.79086 16 12 16C12.3583 16 12.7056 15.9529 13.0361 15.8645L8.18772 11.0162Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'HidePassIcon',
  props: {
    size: { type: Number, required: false, default: 24 },
    fill: { type: String, default: 'currentColor' },
  },
};
</script>
