<template>
  <svg :width="size" :height="size" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.9497 35.117C36.0186 31.739 37.0803 28.5942 38.8544 25.9711C40.0678 24.1771 41.6144 22.6272 43.4045 21.4135C46.0201 19.6403 49.1556 18.5849 52.5314 18.5353C49.1563 18.4887 46.0208 17.4336 43.405 15.6596C41.6155 14.446 40.0692 12.8958 38.8559 11.1019C37.0809 8.47745 36.0185 5.33127 35.9497 1.95361C35.9228 5.33766 34.8777 8.48291 33.1073 11.1046C31.8936 12.9018 30.339 14.453 28.5379 15.6664C25.9088 17.4377 22.7543 18.4891 19.368 18.5353C22.7529 18.5844 25.9075 19.6378 28.5372 21.4103C30.3373 22.6236 31.8915 24.1739 33.1051 25.9697C34.8767 28.5907 35.9228 31.7346 35.9497 35.117ZM35.9808 21.4768C36.8648 20.4068 37.8481 19.4217 38.9167 18.5363C37.8491 17.6513 36.8667 16.6669 35.9834 15.5976C35.0971 16.6688 34.1101 17.654 33.0374 18.5389C34.1089 19.423 35.0951 20.407 35.9808 21.4768Z"
      :fill="fill"
    />
    <path
      d="M58.3331 29.9999C58.3331 32.7613 56.0945 34.9999 53.3331 34.9999C53.0338 34.9999 52.7406 34.9736 52.4557 34.9232L44.7024 44.1302C44.895 44.6618 45 45.2353 45 45.8333C45 48.5947 42.7614 50.8333 40 50.8333C37.3688 50.8333 35.2123 48.8009 35.0147 46.2204L23.6614 40.7905C22.857 41.3433 21.8828 41.6667 20.8331 41.6667C20.5338 41.6667 20.2406 41.6404 19.9557 41.59L13.7858 48.9169C14.0309 49.5071 14.1662 50.1544 14.1662 50.8333C14.1662 53.5947 11.9277 55.8333 9.16624 55.8333C6.40481 55.8333 4.16624 53.5947 4.16624 50.8333C4.16624 48.0719 6.40481 45.8333 9.16624 45.8333C9.38473 45.8333 9.59996 45.8473 9.81105 45.8745L16.1307 38.3699C15.9381 37.8384 15.8331 37.2648 15.8331 36.6667C15.8331 33.9053 18.0717 31.6667 20.8331 31.6667C23.4643 31.6667 25.6208 33.6991 25.8183 36.2797L37.1717 41.7095C37.9761 41.1568 38.9502 40.8333 40 40.8333C40.2993 40.8333 40.5926 40.8596 40.8774 40.9101L48.6307 31.7031C48.4381 31.1715 48.3331 30.5979 48.3331 29.9999C48.3331 27.2385 50.5717 24.9999 53.3331 24.9999C56.0945 24.9999 58.3331 27.2385 58.3331 29.9999Z"
      :fill="fill"
    />
    <path
      d="M19.0793 25.9203C17.003 25.9814 15.1071 26.7541 13.6265 28.0032C13.2423 28.3273 12.8861 28.6836 12.562 29.0678C11.3129 30.5483 10.5402 32.4442 10.4791 34.5205C10.418 32.4442 9.64533 30.5483 8.39624 29.0678C8.0721 28.6836 7.71587 28.3273 7.33167 28.0032C5.85114 26.7541 3.95519 25.9814 1.87891 25.9203C3.95519 25.8592 5.85114 25.0866 7.33167 23.8375C7.71587 23.5133 8.0721 23.1571 8.39624 22.7729C9.64533 21.2924 10.418 19.3964 10.4791 17.3201C10.5402 19.3964 11.3129 21.2924 12.562 22.7729C12.8861 23.1571 13.2423 23.5133 13.6265 23.8375C15.1071 25.0866 17.003 25.8592 19.0793 25.9203Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: '',
  props: {
    fill: { type: String, required: false, default: '#1D1E20' },
    size: { type: Number, required: false, default: 60 },
  },
};
</script>
