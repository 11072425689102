<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.7017 8.84361C18.9647 9.97266 19.8316 11.2404 20.2875 12C19.8316 12.7596 18.9647 14.0273 17.7017 15.1564C16.2517 16.4526 14.3586 17.5 11.9999 17.5C9.64121 17.5 7.74814 16.4526 6.29812 15.1564C5.03507 14.0273 4.16814 12.7596 3.7123 12C4.16814 11.2404 5.03507 9.97266 6.29812 8.84361C7.74814 7.54743 9.64121 6.5 11.9999 6.5C14.3586 6.5 16.2517 7.54743 17.7017 8.84361ZM21.7789 11.5819C21.926 11.8448 21.926 12.1552 21.7789 12.4181C20.9352 13.9265 17.6755 19 11.9999 19C6.32429 19 3.06462 13.9265 2.22089 12.4181C2.07383 12.1552 2.07383 11.8448 2.22089 11.5819C3.06462 10.0735 6.32429 5 11.9999 5C17.6755 5 20.9352 10.0735 21.7789 11.5819ZM15.9999 12C15.9999 14.2091 14.209 16 11.9999 16C9.79076 16 7.9999 14.2091 7.9999 12C7.9999 11.7369 8.0253 11.4797 8.07378 11.2308C8.14633 10.8583 8.63802 10.7854 8.99542 10.9131C9.15307 10.9693 9.3229 11 9.4999 11C10.3283 11 10.9999 10.3284 10.9999 9.5C10.9999 9.32301 10.9692 9.15317 10.913 8.99552C10.7853 8.63812 10.8582 8.14644 11.2307 8.07388C11.4796 8.0254 11.7368 8 11.9999 8C14.209 8 15.9999 9.79086 15.9999 12Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'HidePassIcon',
  props: {
    size: { type: Number, required: false, default: 24 },
    fill: { type: String, default: 'currentColor' },
  },
};
</script>
